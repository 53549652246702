// export const SERVER_URL = "https://ysslapi.thussat.cn"; // 测试环境
// export const DEVELOPMENT_SERVER_URL = "https://ysslapi.thussat.cn"; //测试环境
// export const SERVER_WS_URL = "wss://ysslapi.thussat.cn"; // websocket

// 官网(已废弃)
// export const SERVER_URL = "https://api.thussat.com"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "https://api.thussat.com"; //开发环境
// export const SERVER_WS_URL = "wss://api.thussat.com"; // websocket

// 官网
export const SERVER_URL = "https://gw.thussat.cn"; // 正式环境
export const DEVELOPMENT_SERVER_URL = "https://gw.thussat.cn"; //开发环境
export const SERVER_WS_URL = "wss://gw.thussat.cn"; // websocket

// 剑桥官网
// export const SERVER_URL = "https://api.testgate.cn"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "https://api.testgate.cn"; //开发环境
// export const SERVER_WS_URL = "wss://api.testgate.cn"; // websocket
