import { post, get } from "r/request";
import { request } from "r/index/network";

/**
 * 公共模块
 * */
//获取所有省份列表
export function getProvinceList(data) {
  return request({
    method: "get",
    url: "/web/config/getProvinceList",
    data,
  });
}
//获取所有城市列表
export function getCityList(data) {
  return request({
    method: "get",
    url: "/web/config/getCityList",
    data,
  });
}
//获取所有学校列表
export function getSchoolList(data) {
  return request({
    method: "get",
    url: "/web/config/getSchoolList",
    params: data,
  });
}
//获取所有大学列表
export function getUniversity(data) {
  return request({
    method: "get",
    url: "/web/config/getUniversity?is_abroad=" + data,
  });
}
//获取大学专业
export function getProfession(uuid, id) {
  return request({
    method: "get",
    url: "/web/config/getProfessionNew?university=" + uuid + "&parent_id=" + id,
  });
}
//获取学科列表
export function getSubjects(data) {
  return request({
    method: "get",
    url: "/web/config/getSubjects",
    data,
  });
}
//获取学科列表
export function getStep1(data) {
  return request({
    method: "post",
    url: "/web/register/setStudentInfo/getStep1",
    data,
  });
}

/**
 * 报名相关
 * */

//报名初始化
export function signUpInit(data) {
  return request({
    method: "post",
    url: "/web/signUp/signUpInit",
    data,
  });
}

//页面个人信息获取
export function getAccount(data) {
  return request({
    method: "post",
    url: "/web/signUp/getAccount",
    data,
  });
}

//页面个人信息保存确认
export function setAccount(data) {
  return request({
    method: "post",
    url: "/web/signUp/setAccount",
    data,
  });
}
//绑定邀请码
export function setInviteCode(data) {
  return request({
    method: "post",
    url: "/web/signUp/setInviteCode",
    data,
  });
}
//解绑邀请码
export function cancelInviteCode(data) {
  return request({
    method: "post",
    url: "/web/tools/unCode",
    data,
  });
}

//获取省份与城市
export function getProAndCity(data) {
  return request({
    method: "post",
    url: "/web/signUp/getProAndCity",
    data,
  });
}

// 获取申请候补城市
export function getAlternateCity(data) {
  return request({
    method: "post",
    url: "/web/signUp/getAlternateCity",
    data,
  });
}

//获取申请候补城市列表
export function getApplyAlternateCity(data) {
  return request({
    method: "post",
    url: "/web/signUp/getApplyAlternateCity",
    data,
  });
}

//取消报名
export function cancel(data) {
  return request({
    method: "post",
    url: "/web/signUp/cancel",
    data,
  });
}

//export const getInfo = (p) => post("/web/signUp/getInfo", p); // 获得报名信息

/**
 * 考位预定模块
 * */

//预定城市考位
export function seat(data) {
  return request({
    method: "post",
    url: "/web/book/seat",
    hasLoading: true,
    loadingText: "预定城市考位中",
    data,
  });
}

//取消预订
export function unSeat(data) {
  return request({
    method: "post",
    url: "/web/book/unSeat",
    data,
  });
}

//// 申请候补
export function substitute(data) {
  return request({
    method: "post",
    url: "/web/book/substitute",
    data,
  });
}

//取消候补
export function unSubstitute(data) {
  return request({
    method: "post",
    url: "/web/book/unSubstitute",
    data,
  });
}

//提交建议候补城市
export function subAltCity(data) {
  return request({
    method: "post",
    url: "/web/book/subAltCity",
    data,
  });
}

//获取省份与城市(旅居史14)
export function getProAndCityHistory(data) {
  return request({
    method: "post",
    url: "/web/signUp/getProAndCity14",
    data,
  });
}
//获取当前报备状态
export function getHomeApply(data) {
  return request({
    method: "post",
    url: "/web/signUp/getHomeApply",
    params: data,
  });
}

//修改或新增当前报备
export function updateHomeApply(data) {
  return request({
    method: "post",
    url: "/web/signUp/updateHomeApply",
    data,
  });
}

//获取居家考上传图片配置
export function checkHomeApplyProve(data) {
  return request({
    method: "post",
    url: "/web/signUp/checkHomeApplyProve",
    data,
  });
}
/**
 * 订单模块
 * */

/**
 * 支付及工具接口
 * */
