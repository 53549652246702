<template>
  <div
    class="base-input"
    :class="{
      line: theme == 'line',
      'gray-bg': bgTheme == 'gray',
      textarea: type == 'textarea',
      'no-border': noBorder
    }"
  >
    <span v-if="label" class="mr4 ml10 tc3">{{ label }}</span>
    <textarea
      :value="value"
      v-bind="$attrs"
      @input="e => $emit('input', e.target.value)"
      v-if="type == 'textarea'"
      :rows="rows"
    ></textarea>
    <input
      v-else
      :type="type"
      :value="value"
      v-bind="$attrs"
      :style="{ 'text-align': align }"
      @input="e => $emit('input', e.target.value)"
    />
    <slot name="suffix"></slot>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    value: String,
    align: {
      type: String,
      default: 'left'
    },
    theme: {
      type: String
    },
    type: {
      default: 'text'
    },
    bgTheme: String,
    label: String,
    noBorder: Boolean,
    rows: {
      default: 6
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.base-input {
  width: 100%;
  height: 40px;
  border: none;
  &:not(.no-border) {
    position:relative
  }
  display: flex;
  align-items: center;
  &.no-border {
    border: none;
  }
  input,
  textarea {
    flex-grow: 1;
    padding: 0 10px;
    background: transparent;
  }
  &.line {
    border: 1px solid #dddddd;
    background: #fff;
    border-radius: 5px;
    &.gray-bg {
      background: #f5f5f5;
    }
  }
  &.textarea {
    height: auto;
    padding: 10px 0;
  }
}
</style>
