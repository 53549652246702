import { SERVER_URL } from "../config/server";
import axios from "axios";
import router from "../router/index";
import store from "@/store";
const qs = require("qs");
const instance = axios.create({
  baseURL: SERVER_URL,
  timeout: 60000,
  method: "post",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

instance.interceptors.request.use(
  (config) => {
    console.log(config);
    if (config.method === "post" && !config.notQs) {
      config.data = qs.stringify(config.data);
    }
    if (config.url.indexOf(SERVER_URL) === -1) {
      console.log("upload");
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    // 调用接口请求添加token认证信息
    let token = localStorage.getItem("index-token");
    if (token) config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;

/**
 * axios:get 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(SERVER_URL + url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * axios:post 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(SERVER_URL + url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * axios:upload 文件上传方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function upload(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export const sendRequest = (url, data = {}, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      url,
      data,
      ...config,
    })
      .then((res) => {
        if (res.status === 1) {
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject();
      });
  });
};
