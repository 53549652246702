import {signUpInit} from "r/index/signUp";
import ElementUI from 'element-ui';
import router from "../router";

export async function signUpInitReq(examUuid, type = 1) {
    // type 1-路由跳转 2-城市列表
    const {data: res} = await signUpInit({exam_uuid: examUuid});
    console.log(res, "报名初始化完成");
    if (res.code !== 200) {
        if (res.code == 4002139) {
            await router.replace({
                name: "StepOne",
                query: {applicationstatus: 0},
            });
            ElementUI.Message.info(res.message);
            return false;
        } else if (res.code == 4002131) {
            await router.replace({
                name: "OrderList"
            });
            ElementUI.Message.info(res.message);
            return false;
        }
        ElementUI.Message.error(res.message);
        return false;
    }
    if (type == 2) {
        // 城市列表页面直接获取数据 不做跳转
        return res;
    }
    window.localStorage.setItem("exam_uuid", res.exam_uuid);
    window.localStorage.setItem("order_no", res.order_no);
    window.localStorage.setItem("enroll_end_time", res.enroll_end_time);
    if (res.status == 1) {
        console.log("报名-填写邀请码");
        await router.push({name: "invatation",});
    } else if (res.status == 2) {
        // 跳过邀请码
        console.log("报名-选择考点城市");
        await router.push({name: "SignUpLists"});
    } else if (res.status == 3) {
        // 绑定邀请码
        console.log("报名-选择考点城市");
        await router.push({name: "SignUpLists"});
    } else if (res.status == 4) {
        console.log("报名-确认报名信息");
        await router.push({name: "ConfirmInfo"});
    } else if (res.status == 5) {
        console.log("报名-待支付状态");
        await router.push({name: "ConfirmInfo"});
    } else if (res.status == 7) {
        console.log("报名-订单超时");
        await router.push({name: "SignUpLists"});
    } else if (res.status == 8) {
        console.log("报名-取消报名");
        await router.push({name: "invatation"});
    }
    return true;
}

export async function SignUpInitStatus(examUuid) {
    const {data: res} = await signUpInit({exam_uuid: examUuid});
    console.log(res, "报名初始化完成");
    if (res.code !== 200) {
        if (res.code == 4002139) {
            await router.replace({
                name: "StepOne",
                query: {applicationstatus: 0},
            });
            ElementUI.Message.info(res.message);
            return true;
        } else if (res.code == 4002131) {
            await router.replace({
                name: "OrderList"
            });
            ElementUI.Message.info(res.message);
            return true;
        }
        ElementUI.Message.error(res.message);
        return true;
    }
    window.localStorage.setItem("exam_uuid", res.exam_uuid);
    window.localStorage.setItem("order_no", res.order_no);
    window.localStorage.setItem("enroll_end_time", res.enroll_end_time);
    if (res.status == 2) {
        // 跳过邀请码
        console.log("报名-选择考点城市");
        return false;
    } else if (res.status == 3) {
        // 绑定邀请码
        console.log("报名-选择考点城市");
        await router.push({name: "SignUpLists"});
        return true;
    } else if (res.status == 4) {
        console.log("报名-确认报名信息");
        await router.push({name: "ConfirmInfo"});
        return true;
    } else if (res.status == 5) {
        console.log("报名-待支付状态");
        await router.push({name: "ConfirmInfo"});
        return true;
    } else if (res.status == 7) {
        console.log("报名-订单超时");
        await router.push({name: "SignUpLists"});
        return true;
    } else if (res.status == 8) {
        console.log("报名-取消报名");
        return false;
    } else {
        return false;
    }
}
