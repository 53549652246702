<template>
  <div id="app">
    <router-view />
    <my-dialog
      :dialogVisibleFu="$store.state.showDialog"
      :dialogType="$store.state.dialogType"
      :dialogMessage="$store.state.dialogMessage"
    ></my-dialog>
  </div>
</template>

<script>
import MyDialog from "./components/MyDialog";
export default {
  data() {
    return {
      dialogVisibleFu: true,
    };
  },
  components: {
    MyDialog,
  },
  mounted() {
    let token = window.localStorage.getItem("index-token");
    if (token) {
      this.$store.state.token = token;
    }
  },
};
</script>

<style scoped lang="scss">
@import "~a/css/base.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  height: 100%;
}
</style>
<style>
.el-loading-spinner .el-loading-text {
  color: #563279 !important;
}
.el-loading-spinner .path {
  stroke: #563279 !important;
}
</style>
