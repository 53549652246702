export default {
  // 更新上一页信息
  setPrePage(state, path) {
    state.previousPage = path;
  },
  // 保存数据
  setData(state, city) {
    state.data = city;
  },

  login(state) {
    state.isLogin = "1";
    localStorage.setItem("isLogin", "1");
  },
  loginOut(state) {
    state.isLogin = "0";
    localStorage.removeItem("token");
    localStorage.removeItem("isLogin");
  },
};
