import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import ExamList from "./index/examList";
import SignUp from "./index/signUp";
import Info from "./index/info";
import foundPassword from "./index/foundPassword";
import Register from "./index/register";
import indexLogin from "./index/login";
import ForgetPwd from "./index/forgetPwd";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () =>
        import(/* webpackChunkName: "admin-index" */ "v/Index.vue"),
  },
  // {
  //   path: "/",
  //   name: "index",
  //   redirect: "/examList/examlist",
  //   component: () =>
  //     import(/* webpackChunkName: "admin-index" */ "v/index/Index.vue"),
  //   children: [...ExamList, ...SignUp, ...Info, ...foundPassword]
  // },
  // {
  //   path: "/login",
  //   meta: {
  //     title: "登录",
  //   },
  //   component: () => import("v/index/login/Index.vue"),
  //   children: [...indexLogin, ...Register, ...ForgetPwd],
  // },
  { path: '*', redirect: "/" }
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.path != '/')
    return next('/');
  next();
});

// router.beforeEach((to, from, next) => {
//   // 记录上一页
//   if (from) {
//     store.commit("setPrePage", from.path);
//   }
//   // 1.修改页面标题
//   // 判断是否是后台系统
//   let tokenKey = "index-token",
//     toLogin = "/login",
//     toStepOne = "/register/stepOne",
//     toStepTwo = "/register/stepTwo",
//     toStepThree = "/register/stepThree",
//     toStepFour = "/register/stepFour";
//   let token = localStorage.getItem(tokenKey);
//   let can_skip = localStorage.getItem('can_skip');
//   let is_check_id_card = localStorage.getItem('is_check_id_card');
//   let applicationstatus = localStorage.getItem('applicationstatus');
//   let nextUrl = false;
//   console.log(to.path)
//   let has_applicationstatus = (applicationstatus == 3 || applicationstatus == 4 || applicationstatus == 5 || applicationstatus == 6);
//   if (to.meta.authorization && to.meta.authorization === true) {
//     store.commit("setPrePage", to.path);
//     //是否满足未完善信息跳转到对应的页面
//     if(can_skip == 0){
//       if(applicationstatus==0){
//         nextUrl = toStepOne;
//       }
//       else if(has_applicationstatus){
//         if(is_check_id_card != 1){
//           nextUrl = toStepTwo;
//         }else{
//           nextUrl = toStepThree;
//         }
//         if(!token){
//           nextUrl = toLogin;
//         }
//       }
//     }else{
//       if(!token){
//         nextUrl = toLogin;
//       }
//     }
//   }else{
//     if(to.path === toLogin && token){
//       nextUrl = "/";
//     }
//   }
//   if((to.path === toStepTwo || to.path === toStepThree) && has_applicationstatus){
//     nextUrl = false;
//   }
//   if((to.path === toStepTwo || to.path === toStepThree) && !has_applicationstatus){
//     nextUrl = "/";
//   }
//   document.title = to.meta.title;
//   return nextUrl ? next(nextUrl) : next();
// });
export default router;
