import axios from "axios";
import store from "@/store";
import {SERVER_URL, DEVELOPMENT_SERVER_URL} from "../../config/server";

import {Notification, Message, Loading} from "element-ui";
import router from "../../router";

// 正在进行中的请求列表
let reqList = [];

/**
 * 阻止重复请求
 * @param {array} reqList - 请求缓存列表
 * @param {string} url - 当前请求地址
 * @param {function} cancel - 请求中断函数
 * @param {string} errorMessage - 请求中断时需要显示的错误信息
 */
const stopRepeatRequest = function (reqList, url, cancel, errorMessage) {
    const errorMsg = errorMessage || "";
    for (let i = 0; i < reqList.length; i++) {
        if (reqList[i] === url) {
            // cancel(errorMsg);
            return;
        }
    }
    reqList.push(url);
};
/**
 * 允许某个请求可以继续进行
 * @param {array} reqList 全部请求列表
 * @param {string} url 请求地址
 */
const allowRequest = function (reqList, url) {
    for (let i = 0; i < reqList.length; i++) {
        if (reqList[i] === url) {
            reqList.splice(i, 1);
            break;
        }
    }
};
let loadingInstance = null;

export function request(config) {
    const instance = axios.create({
        baseURL:
            process.env.NODE_ENV === "development"
                ? DEVELOPMENT_SERVER_URL
                : SERVER_URL,
        timeout: 60 * 1000,
    });
    instance.interceptors.request.use(
        (config) => {
            let loginUrl = "/web/auth/login",
                setAccountInfo = "/web/register/setAccountInfo",
                getProvinceList = "/web/config/getProvinceList",
                getCityList = "/web/config/getCityList",
                getSchoolList = "/web/config/getSchoolList",
                checkIdCard = "/web/auth/checkIdCard",
                passMsg = "/web/auth/sendPassMsg",
                loginOut = "/web/auth/loginOut";
            if (
                config.url == loginUrl ||
                config.url == passMsg ||
                config.url == setAccountInfo ||
                config.url == getProvinceList ||
                config.url == getCityList ||
                config.url.indexOf(getSchoolList) > -1 ||
                config.url == checkIdCard
            ) {
                return config;
            }
            let token = localStorage.getItem("index-token");
            if (token) {
                let thatToken = store.state.token;
                if (thatToken != token && config.url != loginOut) {
                    //window.location.reload();
                    store.state.showDialog = true;
                    store.state.dialogType = 0;
                    store.state.dialogMessage = '';
                    let error = new Error("登录账号有问题！");
                    return Promise.reject(error);
                }
                config.headers.token = token;
            } else {
                // 清除本地的 token
                let studentId = window.localStorage.getItem("student_id");
                for (let k in window.localStorage) {
                    if (k != "exam_notice_" + studentId && k != "agree-login") {
                        window.localStorage.removeItem(k);
                    }
                }
                store.state.token = "";
                store.state.isLogin = false;
                router.push({
                    path: "/login",
                    query: {redirect: router.currentRoute.fullPath}, //从哪个页面跳转
                });
                let error = new Error("请登录！");
                return Promise.reject(error);
            }

            let cancel;
            // 设置cancelToken对象
            config.cancelToken = new axios.CancelToken(function (c) {
                cancel = c;
            });
            if (
                config.url.indexOf("/web/config/getUniversity") == -1 &&
                config.url.indexOf("/web/config/getProfession") == -1
            ) {
                // 阻止重复请求。当上个请求未完成时，相同的请求不会进行
                stopRepeatRequest(
                    reqList,
                    config.url,
                    cancel,
                    `${config.url} 请求被中断`
                );
            }

            if (config.hasLoading) {
                let loadingText = config.loadingText ? config.loadingText : "请求中";
                loadingInstance = Loading.service({
                    lock: true,
                    fullscreen: true,
                    text: loadingText,
                    // spinner: 'el-icon-loading',
                    background: "rgba(0, 0, 0, 0.25)",
                });
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            if (res.data.code == 400003 ||
                res.data.code == 399999) {
                // 清除本地的 token
                let studentId = window.localStorage.getItem("student_id");
                for (let k in window.localStorage) {
                    if (k != "exam_notice_" + studentId && k != "agree-login") {
                        window.localStorage.removeItem(k);
                    }
                }

                store.state.showDialog = true;
                store.state.dialogType = 2;
                store.state.dialogMessage = res.data.message;

                this.$store.state.token = "";
                this.$store.state.isLogin = 0;
                router.push({
                    path: "/login",
                    query: {redirect: router.currentRoute.fullPath}, //从哪个页面跳转
                });
            } else if (
                res.data.code == 400801 ||
                res.data.code == 400910 ||
                res.data.code == 4002132 ||
                res.data.code == 400410 ||
                res.data.code == 401001
            ) {
                store.state.showDialog = true;
                store.state.dialogType = 1;
                store.state.dialogMessage = '';
            } else if (res.data.code == 4000344 || res.data.code == 4000345 || res.data.code == 4000348) {
                // 未完善个人信息-兼容老用户
                router.push({
                    path: "/Info/changepersonalInfo",
                    query: {
                        flag: true,
                    }
                });
            }
            loadingInstance && loadingInstance.close();
            setTimeout(() => {
                allowRequest(reqList, res.config.url);
            }, 1000);
            return res;
        },
        (error) => {
            Message.closeAll();
            // if (error.response.status === 401) {
            //   // 未登录
            //   Notification({
            //     message: "身份验证失败，请登录",
            //     duration: 1500,
            //     onClose() {
            //       console.log('error');
            //       router.push("/admin/login");
            //     },
            //   });
            //   return false;
            // }
            if (axios.isCancel(error)) {
                // console.log(error.message);
            } else {
                // 增加延迟，相同请求不得在短时间内重复发送
                setTimeout(() => {
                    allowRequest(reqList, error.config.url);
                }, 1000);
            }
            loadingInstance && loadingInstance.close();
            return Promise.reject(error);
        }
    );

    return instance(config);
}

// 合并多个请求
export function allRequest(arrRequest) {
    return axios.all(arrRequest);
}
