<template>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="520px"
            top="0"
            :close-on-click-modal="false"
    >
        <div class="info_2" v-if="dialogType==0">
            账号登录异常，请重新登录或刷新页面！
        </div>
        <span slot="footer" class="dialog-footer" v-if="dialogType==0">
      <el-button class="cancel" @click="backLogin()">重新登录</el-button>
      <el-button class="confirm" @click="reloadPage()">刷新页面</el-button>
    </span>

        <div class="info_2" v-if="dialogType==1">
            报名进度异常，请返回考试列表或继续报名！
        </div>
        <span slot="footer" class="dialog-footer" v-if="dialogType==1">
      <!-- 去注册区域 -->
      <el-button class="cancel" @click="backIndex()">返回考试列表</el-button>
      <el-button class="confirm" @click="toSignUp()"
                 :disabled="isDisable">继续报名</el-button>
    </span>

        <div class="info_2" v-if="dialogType==2">
            {{dialogMessage}}
        </div>
        <span slot="footer" class="dialog-footer" v-if="dialogType==2">
      <el-button class="confirm" @click="backLogin()">重新登录</el-button>
    </span>
    </el-dialog>
</template>

<script>
    // import {signUpInit} from "r/index/signUp";
    import {signUpInitReq} from "common/request";

    export default {
        name: "MyDialog",
        props: {
            dialogVisibleFu: Boolean,
            dialogType: [String, Number],
            dialogMessage: [String, Number]
        },
        data() {
            return {
                dialogVisible: this.dialogVisibleFu,
                isDisable: false, // 禁用 false-不禁用，true-禁用
            };
        },
        methods: {
            // 返回登录页面
            backLogin() {
                this.dialogVisible = false;
                this.$store.state.showDialog = false
                // 清除本地的 token
                let studentId = window.localStorage.getItem("student_id");
                for (let k in window.localStorage) {
                    if (k != "exam_notice_" + studentId && k != "agree-login") {
                        window.localStorage.removeItem(k);
                    }
                }
                this.$router.replace('/login')
            },
            // 取消,关闭弹框，修改父组件的值
            reloadPage() {
                this.dialogVisible = false;
                this.$store.state.showDialog = false
                window.location.reload();
            },
            // 返回考试列表
            backIndex() {
                this.dialogVisible = false;
                this.$store.state.showDialog = false
                this.$router.replace({name: 'examlist'})
            },
            // 继续报名
            async toSignUp() {
                this.dialogVisible = false;
                this.$store.state.showDialog = false
                if (this.isDisable) {
                    return false;
                }
                this.isDisable = true;
                let examuuid = window.localStorage.getItem("exam_uuid")
                await signUpInitReq(examuuid);
                this.isDisable = false;
                // const {data: res} = await signUpInit({exam_uuid: window.localStorage.getItem("exam_uuid")});
                // console.log(res, "报名初始化完成");
                // if (res.code !== 200) {
                //     if (res.code == 4002139) {
                //         await this.$router.replace({name: 'StepOne', query: {applicationstatus: 0}})
                //         return this.$message.info(res.message);
                //     } else if (res.code == 4002131) {
                //         await this.$router.replace({name: 'OrderList'})
                //         return this.$message.info(res.message);
                //     }
                //     return this.$message.error(res.message);
                // }
                // window.localStorage.setItem("exam_uuid", res.exam_uuid);
                // window.localStorage.setItem("order_no", res.order_no);
                // this.isDisable = false;
                // if (res.status == 1) {
                //     console.log("报名-填写邀请码");
                //     await this.$router.push({name: "invatation",});
                // } else if (res.status == 2) {
                //     // 跳过邀请码
                //     console.log("报名-选择考点城市");
                //     await this.$router.push({name: "SignUpLists"});
                // } else if (res.status == 3) {
                //     // 绑定邀请码
                //     console.log("报名-选择考点城市");
                //     await this.$router.push({name: "SignUpLists"});
                // } else if (res.status == 4) {
                //     console.log("报名-确认报名信息");
                //     await this.$router.push({name: "ConfirmInfo"});
                // } else if (res.status == 5) {
                //     console.log("报名-待支付状态");
                //     await this.$router.push({name: "ConfirmInfo"});
                // } else if (res.status == 7) {
                //     console.log("报名-订单超时");
                //     await this.$router.push({name: "SignUpLists"});
                // } else if (res.status == 8) {
                //     console.log("报名-取消报名");
                //     await this.$router.push({name: "invatation"});
                // }
            },
        },
        watch: {
            dialogVisibleFu() {
                this.dialogVisible = this.dialogVisibleFu;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../assets/scss/dialog";

    ::v-deep .el-dialog__body {
        padding: 0 48px 30px 48px !important;
    }

    ::v-deep .el-dialog__footer {
        margin: 0;
        padding: 10px 0 30px !important;
        text-align: center;
    }

    ::v-deep .el-icon-close:before {
        content: "";
    }
</style>
